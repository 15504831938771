const env = {
    url: 'https://api.ambientaly-hml.innovareti.com.br/',
    api: 'https://api.ambientaly-hml.innovareti.com.br/api/v1/',
    applicationName: 'AMBIENTALY',
    applicationFooter: 'AMBIENTALY',
    chartBarBackgroundColor: '#5BA935',
    isAmbientaly: true
}

export default env
